var geonames = {

    fill: function ($node, key, value, empty, prefix, callback) {
        var data = geonames.constructor.map[key];
        var json = $node.attr('data-json');

        $node.html("");
        if (empty)
        // aggiungo la option vuota
            $node.append('<option value="">&nbsp;</option>');

        $.each(data, function () {
            var selected, id, label, label_base, val;
            if (prefix) {
                var phone = this.phone.replace("+", "").replace("-", "");
                selected = value == phone ? "selected" : "";
                id = phone;
                label = this.alternate_name + ' (+' + phone + ')';
                label_base = this.alternate_name_base;
            } else {
                selected = value == this.geonameid ? "selected" : "";
                id = this.geonameid;
                label = this.alternate_name;
                label_base = this.alternate_name_base;
            }
            if (json) {
                val = JSON.stringify({
                    geonameid: id,
                    alternate_name: label,
                    alternate_name_base: label_base
                });
            } else {
                val = "" + id;
            }
            $node.append('<option value=\'' + val.replace(/'/g, "&apos;").replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;") + '\' data-continent="' + this.continent + '" data-countrycode="' + this.iso_alpha2 + '" ' + selected + '>' + label + '</option>');
            if (selected)
            // ho selezionato qualcosa quindi scateno il trigger del click
                $node.trigger('change');
        });

        callback();
    },

    load: function ($node, url, prefix, callback) {
        var preload = $node.attr('data-ajax');
        preload = typeof preload == "undefined" || preload == "true" || preload == "1";
        var value = $node.attr('data-value');
        var lang = $node.attr('data-lang');
        var empty = parseInt($node.attr('data-empty')) || 1;
        var type = $node.attr('data-geoname');
        var key, country, province;

        if ($.trim(value) == "")
            value = "##-##-##"; // valore inesistente -> fix per value = "" -> conflitto!

        switch (type) {
            case 'prefix':
            case 'country':
                key = 'countries';
                break;
            case 'province':
                country = $node.attr('data-country');
                key = 'countries_' + country;
                break;
            case 'city':
                country = $node.attr('data-country');
                province = $node.attr('data-province');
                key = 'countries_' + country + "_" + province;
                break;
        }

        prefix = prefix || false;

        if (!preload) {
            // TODO: select2 ajax
        } else {
            if (geonames.constructor.map[key]) {
                geonames.fill($node, key, value, empty, prefix, callback);
            } else {
                // faccio una chiamata per recuperare le entries
                $node.attr('disabled', 'disabled');
                $.get(url, {lang: lang})
                    .done(function (data) {
                        geonames.constructor.map[key] = data;

                        geonames.fill($node, key, value, empty, prefix, callback);

                        $node.removeAttr('disabled');
                    })
                    .fail(function (data) {
                        app.error("", app.parseAjaxError(data));
                        $node.removeAttr('disabled');
                    });
            }
        }
    },

    init: function () {
        // trasformo i data-geoname in select2 precaricando o meno i dati
        $('[data-geoname=country]').each(function () {
            var tags = $(this).attr('data-tags');
            tags = typeof tags != "undefined" && (tags == "true" || tags == "1");

            $(this).select2({
                minimumResultsForSearch: 8,
                tags: tags
            });
        });

        $('[data-geoname=province]').each(function () {
            var tags = $(this).attr('data-tags');
            tags = typeof tags != "undefined" && (tags == "true" || tags == "1");

            $(this).select2({
                minimumResultsForSearch: 8,
                tags: tags
            });
        });

        $('[data-geoname=city]').each(function () {
            var tags = $(this).attr('data-tags');
            tags = typeof tags != "undefined" && (tags == "true" || tags == "1");

            $(this).select2({
                minimumResultsForSearch: 8,
                tags: tags
            });
        });

        $('[data-geoname=prefix]').each(function () {
            var tags = $(this).attr('data-tags');
            tags = typeof tags != "undefined" && (tags == "true" || tags == "1");

            $(this).select2({
                minimumResultsForSearch: 8,
                tags: tags
            });
        });

        // eseguo il bind degli eventi
        geonames.bindPrefixesEvents();
        geonames.bindCountryEvents();
        geonames.bindProvinceEvents();
        geonames.bindCityEvents();

        var fnz = function ($node, $prefix) {
            // abilito country, province city e prefix
            $('[data-geoname=country]').removeAttr('disabled');
            $('[data-geoname=province]').removeAttr('disabled');
            $('[data-geoname=city]').removeAttr('disabled');
            $('[data-geoname=prefix]').removeAttr('disabled');

            if (typeof $prefix != 'undefined') {
                $prefix.trigger('geonames.prefixes.load');
                $prefix.trigger('geonames.prefix.load');
            }

            $('[data-geoname=country]').each(function () {
                var $country = $(this);

                // disabilito country, province e city
                $('[data-geoname=country]').attr('disabled', 'disabled');
                $('[data-geoname=province]').attr('disabled', 'disabled');
                $('[data-geoname=city]').attr('disabled', 'disabled');

                // carico i dati
                geonames.load($(this), app.siteUrl + "/countries", false, function () {
                    // abilito country, province e city
                    $('[data-geoname=country]').removeAttr('disabled');
                    $('[data-geoname=province]').removeAttr('disabled');
                    $('[data-geoname=city]').removeAttr('disabled');

                    // trigger evento load
                    $country.trigger('geonames.countries.load');
                    $country.trigger('geonames.country.load');
                });
            });
        };

        if ($('[data-geoname=prefix]').length > 0) {
            $('[data-geoname=prefix]').each(function () {
                var $prefix = $(this);

                // disabilito country, province city e prefix
                $('[data-geoname=country]').attr('disabled', 'disabled');
                $('[data-geoname=province]').attr('disabled', 'disabled');
                $('[data-geoname=city]').attr('disabled', 'disabled');
                $('[data-geoname=prefix]').attr('disabled', 'disabled');

                // carico i dati
                geonames.load($(this), app.siteUrl + "/countries", true, function () {
                    fnz($prefix);
                });
            });
        } else {
            fnz();
        }
    },

    bindCountryEvents: function () {
        $('[data-geoname=country]').on('change', function () {
            var group = $(this).attr('data-group');
            var country = $(this).val();
            var $provinces, $cities, $prefixes;
            var json = $(this).attr('data-json');

            if (typeof group != "undefined") {
                $provinces = $('[data-geoname=province][data-group=' + group + ']');
                $cities = $('[data-geoname=city][data-group=' + group + ']');
                $prefixes = $('[data-geoname=prefix][data-group=' + group + ']');
            } else {
                $provinces = $('[data-geoname=province]');
                $cities = $('[data-geoname=city]');
                $prefixes = $('[data-geoname=prefix]');
            }

            geonames.countries = undefined;

            // devo caricare le province
            if ($provinces.length > 0 && $.trim(country) != "") {
                if (json) {
                    country = JSON.parse(country).geonameid;
                }

                $provinces.html("").attr('data-country', country).trigger('change');
                $cities.html("").attr('data-country', country).trigger('change');

                // ho dei selettori province per il mio gruppo quindi ne carico il contenuto in base alla country selezionata
                $provinces.each(function () {
                    var $province = $(this);

                    // disabilito province e city
                    $('[data-geoname=province]').attr('disabled', 'disabled');
                    $('[data-geoname=city]').attr('disabled', 'disabled');

                    // carico i dati
                    geonames.load($(this), app.siteUrl + "/countries/" + country + "/provinces", false, function () {
                        // abilito province e city
                        $('[data-geoname=province]').removeAttr('disabled');
                        $('[data-geoname=city]').removeAttr('disabled');

                        $province.trigger('geonames.provinces.load');
                        $province.trigger('geonames.province.load');
                    });
                });
            }

            // devo selezionare il prefisso corretto
            if ($prefixes.length > 0 && $.trim(country) != "") {
                var code = $(this).find('option:selected').attr('data-countrycode');
                var value = $prefixes.find('option[data-countrycode=' + code + ']').attr('value');
                $prefixes.val(value).trigger('change');
            }
        });
    },

    bindProvinceEvents: function () {
        $('[data-geoname=province]').on('change', function () {
            var group = $(this).attr('data-group');
            var province = $(this).val();
            var country = $(this).attr('data-country');
            var $cities;
            var json = $(this).attr('data-json');

            if (typeof group != "undefined") {
                $cities = $('[data-geoname=city][data-group=' + group + ']');
            } else {
                $cities = $('[data-geoname=city]').trigger('change');
            }

            geonames.countries = undefined;

            // devo caricare le province
            if ($cities.length > 0 && $.trim(province) != "") {
                if (json) {
                    province = JSON.parse(province).geonameid;
                }

                $cities.html("").attr('data-province', province).attr('data-country', country);

                // ho dei selettori province per il mio gruppo quindi ne carico il contenuto in base alla country selezionata
                $cities.each(function () {
                    var $city = $(this);

                    // disabilito city
                    $('[data-geoname=city]').attr('disabled', 'disabled');

                    // carico i dati
                    geonames.load($(this), app.siteUrl + "/countries/" + country + "/provinces/" + province + "/cities", false, function () {
                        // abilito city
                        $('[data-geoname=city]').removeAttr('disabled');

                        $city.trigger('geonames.cities.load');
                        $city.trigger('geonames.city.load');
                    });
                });
            }
        });
    },

    bindPrefixesEvents: function () {},

    bindCityEvents: function () {}

};

geonames.constructor.map = {};